import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import ReactGA from 'react-ga';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);

import('./App').then(async ({ default: App }) => {
  const buildVersion = process.env.REACT_APP_BUILD_VERSION;
  const imgBaseURL = process.env.REACT_APP_IMG_BASE_URL;
  window.activeLanguage = navigator.language || navigator.userLanguage;

  const { data: ptBR } = await axios.get(`${imgBaseURL}/i18n/pt-BR.json?v=${buildVersion}`);
  const { data: enUS } = await axios.get(`${imgBaseURL}/i18n/en-US.json?v=${buildVersion}`);
  const { data: trTR } = await axios.get(`${imgBaseURL}/i18n/tr-TR.json?v=${buildVersion}`);
  const { data: esES } = await axios.get(`${imgBaseURL}/i18n/es-ES.json?v=${buildVersion}`);
  const { data: frFR } = await axios.get(`${imgBaseURL}/i18n/fr-FR.json?v=${buildVersion}`);
  const { data: hat } = await axios.get(`${imgBaseURL}/i18n/hat.json?v=${buildVersion}`);

  const resources = {
    'pt-BR': { translation: ptBR },
    en: { translation: enUS },
    tr: { translation: trTR },
    es: { translation: esES },
    fr: { translation: frFR },
    hat: { translation: hat },
  };

  i18n.use(initReactI18next).init({
    lng: 'pt-BR',
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

  ReactDOM.render(
    <React.Fragment>
      <App />
    </React.Fragment>,
    document.getElementById('root'),
  );
});
